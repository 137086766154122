
// @ts-ignore
import { customEvent } from '@petlove/webstore-libs/analytics/events/customEvents';
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';

export default defineComponent({
  layout(ctx: { $device: { isMobile: boolean } }) {
    return ctx.$device.isMobile ? 'mobileSSO' : 'desktopSSO';
  },
  middleware: ['social', 'migration', 'auth'],
  data() {
    return {
      loading: false
    };
  },
  head() {
    return {
      title: 'Entrar'
    };
  },
  computed: {
    ...mapGetters({
      hasPinId: 'sso/hasPinId',
      hasOneTapError: 'sso/hasOneTapError'
    }),
    componentToShow() {
      return this.hasPinId ? 'PinLogin' : 'EmailLogin';
    }
  },
  watch: {
    componentToShow() {
      if (this.hasPinId) customEvent('view_element', 'pin', 'login', 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  mounted() {
    if (this.hasOneTapError) {
      customEvent('login', 'one_tap:login:falha', 'login', 1);
    }
  }
});
