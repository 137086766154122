import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a8e79100&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=a8e79100&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8e79100",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaLoading: require('/app/node_modules/@petlove/caramelo-vue-components/src/Loading/index.vue').default,CaCard: require('/app/node_modules/@petlove/caramelo-vue-components/src/Card/index.vue').default})
